<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <div class="module_item">
        <div class="form_box">
          <el-form-item class="form_item" label="事件名称：" prop="eventName">
            <el-input v-model="formData.eventName"  :disabled=disabled></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="启用状态：" prop="status">
            <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :disabled=disabled
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item class="form_item" label="奖励设置：" prop="title">
            <div class="award_box">
              <div class="award_item">
                <div>直接推广奖励</div>
                <el-select v-model="rewardTypeDirect.type" placeholder="选择奖励方式" :disabled=disabled style="width: 100px; margin-left: 10px">
                  <el-option
                    v-for="(item,index) in rewardType"
                    :key="index"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
                <el-input v-model="rewardTypeDirect.value" :disabled=disabled style="width: 120px; margin-left: 10px">
                  <template slot="append" v-if="rewardTypeDirect.type === 2">%</template>
                  <template slot="append" v-if="rewardTypeDirect.type === 1">元</template>
                </el-input>
              </div>
              <div class="award_item">
                <div>间接推广奖励</div>
                <el-select v-model="rewardTypeInDirect.type" placeholder="选择奖励方式" :disabled=disabled style="width: 100px; margin-left: 10px">
                  <el-option
                    v-for="(item,index) in rewardType"
                    :key="index"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
                </el-select>
                <el-input v-model="rewardTypeInDirect.value" :disabled=disabled style="width: 120px; margin-left: 10px">
                  <template slot="append" v-if="rewardTypeInDirect.type === 2">%</template>
                  <template slot="append" v-if="rewardTypeInDirect.type === 1">元</template>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

    </el-form>
    <div class="form_btn">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm" v-if="!disabled">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

export default {
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        pageTitle: "",
        disabled: false,
        rewardType: [
          {"key": 1, "value": "固定值"},
          {"key": 2, "value": "百分比"}
        ],
        rewardTypeDirect: {
          type: "",
          value: ""
        },
        rewardTypeInDirect: {
          type: "",
          value: ""
        },

        formData: {
          eventNo: '',
          eventName: '',
          status: 1,
          rewardRules: []
        },
        formRules: {
          eventName: { required: true, message: '请输入事件名称', trigger: 'change' }
        },
        option: {
          height: 500
        },
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "代理事件", path: "/insurance/insuranceAgencyEvent/list" },
        {
          name: this.pageTitle
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 图片上传成功
    uploadSuccess(){

    },
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formData.rewardRules.push({"rewardType": 1, rewardRuleOption: this.rewardTypeDirect})
          this.formData.rewardRules.push({"rewardType": 2, "rewardRuleOption": this.rewardTypeInDirect})

          this.$store.dispatch(`insurance/saveAgentEvent`,{
            ...this.formData
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
          })      
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    getDetail(eventNo){
      this.$store.dispatch('insurance/getAgentEventDetail',{
        "eventNo":eventNo
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key];

          for (const rule of this.formData.rewardRules) {
            if (rule.rewardType === 1) {
              this.rewardTypeDirect = rule.rewardRuleOption
            }
            if (rule.rewardType === 2) {
              this.rewardTypeInDirect = rule.rewardRuleOption
            }
          }
          this.formData.rewardRules = [];

          console.log(this.formData)
          console.log(this.rewardTypeInDirect);
          console.log(this.rewardTypeInDirect);
        })
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 新建类型
    // 获取当前路由的名称
    if (this.$route.name === "insuranceAgencyEventAdd") {
      this.pageTitle = "新建"
    } else if (this.$route.name === "insuranceAgencyEventEdit") {
      this.pageTitle = "编辑"
    } else if (this.$route.name === "insuranceAgencyEventShow") {
      this.pageTitle = "查看";
      this.disabled = true
    }
    if (this.$route.query.eventNo) {
      this.getDetail(this.$route.query.eventNo);
    }
  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        // align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
          .form_item_explain{
            font-size: 12px;
            color: #999;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    // input[type="number"] {
    //   -moz-appearance: textfield;
    // }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .award_box{
    display: flex;
    flex-direction: column;
    .award_item{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      >.award_title{
        margin: 0 20px;
      }
      >div:nth-child(1){
        flex-shrink: 0;
      }
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
</style>
